import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { ImportStatement } from '~/components/ImportStatement';
import PageHeader from '~/components/PageHeader';
import { Playground } from '~/components/playground/Playground';
import { Stepper } from '@entur/menu';
import { IconButton } from '@entur/button';
import { Label } from '@entur/typography';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'() => {\n  const [activeIndex, setActiveIndex] = React.useState(0)\n  const steps = [\'Betaling\', \'Kvittering\', \'Billettering\']\n  return (\n    <>\n      <Label>Vanlig stepper</Label>\n      <Stepper steps={steps} activeIndex={1} />\n      <Label>Interaktiv stepper</Label>\n      <Stepper\n        steps={steps}\n        activeIndex={activeIndex}\n        onStepClick={setActiveIndex}\n        interactive\n      />\n      <div style={{ display: \'flex\', justifyContent: \'space-between\' }}>\n        <IconButton\n          onClick={() =>\n            setActiveIndex(activeIndex > 0 ? activeIndex - 1 : 0)\n          }\n        >\n          Tilbake\n        </IconButton>\n        <IconButton\n          onClick={() =>\n            setActiveIndex(activeIndex < 2 ? activeIndex + 1 : 2)\n          }\n        >\n          Frem\n        </IconButton>\n      </div>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      ImportStatement,
      PageHeader,
      Playground,
      Stepper,
      IconButton,
      Label,
      DefaultLayout,
      _frontmatter
    }} style={{
      marginTop: '2rem'
    }} mdxType="Playground">
  {() => {
        const [activeIndex, setActiveIndex] = React.useState(0);
        const steps = ['Betaling', 'Kvittering', 'Billettering'];
        return <>
        <Label mdxType="Label">Vanlig stepper</Label>
        <Stepper steps={steps} activeIndex={1} mdxType="Stepper" />
        <Label mdxType="Label">Interaktiv stepper</Label>
        <Stepper steps={steps} activeIndex={activeIndex} onStepClick={setActiveIndex} interactive mdxType="Stepper" />
        <div style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}>
          <IconButton onClick={() => setActiveIndex(activeIndex > 0 ? activeIndex - 1 : 0)} mdxType="IconButton">
            Tilbake
          </IconButton>
          <IconButton onClick={() => setActiveIndex(activeIndex < 2 ? activeIndex + 1 : 2)} mdxType="IconButton">
            Frem
          </IconButton>
        </div>
      </>;
      }}
    </Playground>
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p><inlineCode parentName="p">{`<Stepper />`}</inlineCode>{`-komponenten skal brukes til skjemaer som krever trinnvis utfylling. Det skal i utgangspunktet ikke være mulig å hoppe `}<em parentName="p">{`framover`}</em>{` i skjemaet uten å ha fylt inn de foregående stegene først.`}</p>
    <p>{`Stepper er en visuell fremstilling av brukerens fremgang gjennom et sett med trinn.
En trinnliste som fungerer som en veiviser for å hjelpe brukerne med å fullføre en spesifikk oppgave i
en arbeidsflyt bestående av et antall trinn. Den gir brukeren en raskt måte å se hvor de er i løpet og
hva de skal jobbe med videre. Vis trinnene i rekkefølge fra venstre mot høyre.
Brukeren kan navigere seg bakover ved å klikke på overskriften på et fullført trinn.`}</p>
    <h3 {...{
      "id": "trinnstatus"
    }}>{`Trinnstatus`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Fullført`}</strong>{`: Vises når trinnet er utført og brukeren oppnår det som kreves for å fortsette til neste trinn.`}</li>
      <li parentName="ul"><strong parentName="li">{`Aktiv`}</strong>{`: Viser det nåværende trinnet, men ikke fullført enda.`}</li>
      <li parentName="ul"><strong parentName="li">{`Inaktiv`}</strong>{`: Viser trinnene som ikke er nådd enda.`}</li>
    </ul>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "stepper-"
    }}>{`<Stepper /`}{`>`}</h3>
    <ImportStatement imports="Stepper" mdxType="ImportStatement" />
    <Props of={Stepper} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      